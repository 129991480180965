import React, { useState, useEffect } from 'react';
import { useQueryParams } from 'hookrouter';
import axios from 'axios';
import format from 'date-fns/format';
import ReactLoading from 'react-loading';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputMask from 'react-input-mask';

import { formatPrice } from '../../util/format';
import history from '../../services/history';
import LogoValeti from '../../assets/images/logo.jpg';
import LogoGoogle from '../../assets/images/google-play.png';
import LogoApple from '../../assets/images/appstore.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://valeti.com/">
        Valeti -
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ff4e00',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: '#ff4e00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff4e00',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ff4e00',
    color: '#fff',
  },
  logo: {
    marginBottom: theme.spacing(2),
    width: 150,
    marginLeft: theme.spacing(14),
  },
  badges: {
    marginBottom: theme.spacing(2),
    width: 150,
    height: 50,
    marginLeft: theme.spacing(14),
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing(2),
    },
    color: theme.palette.text.white,
  },
}));

export default function Index() {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [errorPayment, setErrorPayment] = useState(false);
  const [msgError, setMsgError] = useState(null);
  const [labelCard, setLabelCard] = useState('');
  const [numCard, setNumCard] = useState('');
  const [cvcCard, setCvcCard] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [validateCard, setValidateCard] = useState('');
  const [dateIn, setDateIn] = useState(null);
  const [valet, setValet] = useState(null);
  const [plate, setPlate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [queryParams] = useQueryParams();
  let disabled = true;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios(
          `${process.env.REACT_APP_API_SMS}/infoTicketSms/${queryParams.ticketId}`
        );

        const dataFormatada = format(
          new Date(response.data.dateIn),
          'dd/MM/yyyy HH:mm:ss'
        );

        setDateIn(dataFormatada);
        setValet(response.data.valet.name);
        setPlate(response.data.plate);
        setLoadingData(false);
        setAmountPaid(response.data.amountPaid);
      } catch (err) {
        setMsgError('Erro ao carregar dados. Tente novamente mais tarde.');
        setError(true);
        setLoadingData(false);
      }
    }

    fetchData();
  }, [queryParams.ticketId]);

  if (
    labelCard === '' ||
    cvcCard === '' ||
    validateCard === '' ||
    numCard === '' ||
    amountPaid === 0 ||
    amountPaid === null
  ) {
    disabled = true;
  } else {
    disabled = false;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    disabled = false;
    setLoading(true);

    await axios
      .post(`${process.env.REACT_APP_API_SMS}/pagarTicketSms`, {
        labelCard,
        cvcCard,
        numCard,
        validateCard,
        amountPaid,
        ticketId: queryParams.ticketId,
      })
      .then(() => {
        setLoading(false);
        history.push('/sucesso');
      })
      .catch(() => {
        setLoading(false);
        setErrorPayment(true);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.title}>
        <Typography component="h1" variant="h5">
          <strong>Pagamento de Ticket Online</strong>
        </Typography>
      </div>
      <div className={classes.paper}>
        {loadingData ? (
          <ReactLoading type="spin" color="#FF4E00" height={20} width={20} />
        ) : error ? (
          <>
            <p style={{ color: 'red' }}>{msgError}</p>
          </>
        ) : (
          <>
            <p>
              <strong>Estabelecimento: </strong>
              {valet}
            </p>
            <p>
              <strong>Data de Entrada </strong>
              {dateIn}
            </p>
            <p>
              <strong>Placa: </strong>
              {plate}
            </p>
            <p>
              <strong>Preço a pagar: </strong>
              {formatPrice(amountPaid)}
            </p>
          </>
        )}
        {!loadingData && !error && (
          <form className={classes.form} onSubmit={handleSubmit}>
            <CssTextField
              className={classes.margin}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="labelCard"
              label="Nome Anexado no Cartão"
              name="labelCard"
              autoComplete="labelCard"
              autoFocus
              value={labelCard}
              onChange={(e) =>
                setLabelCard(
                  e.target.value.replace(/[^\w\s]/gi, '').toUpperCase()
                )
              }
              type="labelCard"
            />

            <InputMask
              mask="9999 9999 9999 9999"
              maskChar=""
              className={classes.margin}
              required
              onChange={(e) => setNumCard(e.target.value)}
            >
              {() => (
                <CssTextField
                  className={classes.margin}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="numCard"
                  label="Número do Cartão"
                  name="numCard"
                  autoComplete="numCard"
                />
              )}
            </InputMask>

            <InputMask
              mask="99/99"
              maskChar=""
              className={classes.margin}
              required
              onChange={(e) => setValidateCard(e.target.value)}
            >
              {() => (
                <CssTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="validateCard"
                  label="Data de Validade"
                  name="validateCard"
                  autoComplete="validateCard"
                />
              )}
            </InputMask>

            <InputMask
              mask="999"
              maskChar=""
              className={classes.margin}
              required
              onChange={(e) => setCvcCard(e.target.value)}
            >
              {() => (
                <CssTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="cvcCard"
                  label="CVC (código segurança)"
                  name="cvcCard"
                  autoComplete="cvcCard"
                />
              )}
            </InputMask>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              disabled={disabled}
            >
              {loading && (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              )}
              {!loading && <>Realizar Pagamento</>}
            </Button>
            {errorPayment && (
              <div className={classes.paper}>
                <p style={{ color: 'red' }}>
                  <strong>
                    ERRO AO REALIZAR O PAGAMENTO, POR FAVOR, VERIFIQUE OS DADOS
                    E TENTE NOVAMENTE.{' '}
                  </strong>
                </p>
              </div>
            )}
          </form>
        )}
      </div>
      <img alt="Logo Valeti" className={classes.logo} src={LogoValeti} />
      <p>
        <strong>
          Quer sua vida facilitada e seus tickets sempre pagos de forma rápida?
          Baixe nosso app!
        </strong>
      </p>
      <a
        href="https://apps.apple.com/br/app/free-valet-restaurantes/id1348715851"
        title="Download App Store"
        data-aos="fade-up"
      >
        <img
          alt="Baixe o app Valeti"
          className={classes.badges}
          src={LogoApple}
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.freevaletapp.driver"
        title="Download Play Store"
        data-aos="fade-up"
      >
        <img
          alt="Baixe o app Valeti"
          className={classes.badges}
          src={LogoGoogle}
        />
      </a>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
