import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import LogoValeti from '../../assets/images/logo.jpg';
import LogoGoogle from '../../assets/images/google-play.png';
import LogoApple from '../../assets/images/appstore.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://valeti.com/">
        Valeti -
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  badges: {
    marginBottom: theme.spacing(2),
    width: 150,
    height: 50,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ff4e00',
    color: '#fff'
  },
  logo: {
    marginBottom: theme.spacing(2),
    width: 150
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing(2),
    },
    color: theme.palette.text.white,
  }
}));


export default function Success() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.title}>
        <Typography component="h1" variant="h5">
          <strong>Pagamento de Ticket Online</strong>
        </Typography>
      </div>
      <div className={classes.paper}>
        <img alt='Logo Valeti' className={classes.logo} src={LogoValeti} />
        <br /><br />
        <Typography component="h1" variant="h4">
          Tudo certo com seu pagamento! Obrigado por utilizar a Valeti, e uma boa viagem! 
        </Typography>
        <p><strong>Quer sua vida facilitada e seus tickets sempre pagos de forma rápida? Baixe nosso app!</strong></p>
        <a href="https://apps.apple.com/br/app/valeti/id1069081218" title="Download App Store" data-aos="fade-up"><img alt='Baixe o app Valeti' className={classes.badges} src={LogoApple} /></a>
        <a href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR" title="Download Play Store" data-aos="fade-up"><img alt='Baixe o app Valeti' className={classes.badges} src={LogoGoogle} /></a>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}